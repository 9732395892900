import React from "react";
import {
  Home,
} from "./components";

function App() {
  return (
    <div className="app">
        <Home />
    </div>
  );
}

export default App;